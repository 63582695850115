import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page-404">
       <section>
           <h1>Welcome to</h1>
           <img src="/images/np-msg-visual-1.jpg" className="img-responsive thumb" />
           <p>
             Your requested URL <span className="highlighted grey"></span> was not found on our space,
           </p>
           <p>
             please visit our <a href='.'>Homepage</a> first :)
           </p>
       </section>
     </div>
  </Layout>
)

export default NotFoundPage
